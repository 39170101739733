.ai-tool-usage-meter {
	.service-item {
		border-radius: 10px;
		border: solid 1px rgba(0, 0, 0, .2);
		padding: 15px;		
		color: #fff;
		margin-bottom: 15px;
		&.LumaAI {
			background: #4ECDC4;
		}
		&.LumaRay2 {
			background: #184643;
		}
		&.HaiperVideo {
			background: #FF6B6B ;
		}
		&.RunwayML {
			background: #FFD93D ;
		}
		&.Hunyuan {
			background: #FF9F1C ;
		}
		&.Wan {
			background: #362001 ;
		}
		&.Pixverse {
			background: #FF6B6B ;
		}
		&.KlingAI {
			background: #2EC4B6;
		}
		&.MinimaxHailuo {
			background: #2e79c4;
		}
		&.LeonardoMotion {
			background: #3D5A80 ;
		}
		.service-name {
			font-size: 12px;
		}
		h3, p {
			margin: 0px;
		}
		p {
			font-size: 12px;
		}
		.running {
			display: flex;
			justify-content: center;
			align-items: flex-end;
			width: 100%;
			h3 {
				font-size: 50px;
			}
			p {
				margin-left: 5px;
			}
		}
		.other {
			display: flex;
			justify-content: space-between;
			align-items: center;
			.new, .failed, .processing, .avg-total, .avg-processing {
				text-align: center;
			}
		}
	}
}